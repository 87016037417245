import IconSignOut from '@/assets/icons/icon-sign-out.svg?component';
import type { VNode } from 'vue';
import { NuxtLink } from '#components';
import './NavBarMinimal.css';

export default defineComponent({
  name: 'NavbarPlain',
  props: {
    showSignOut: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { localeProperties } = useI18n();
    const whiteLogo = computed(() =>
      getWhiteLogo(localeProperties.value.brand)
    );
    return {
      whiteLogo,
      props,
    };
  },
  render(): VNode {
    return (
      <nav class="navbar minimal">
        <div class="nav-container">
          <this.whiteLogo class="logo" />
          <ul class="nav-links">
            {this.showSignOut && (
              <li>
                <NuxtLink
                  class="nav-link sign-out-link"
                  to={{ name: 'sign-out' }}
                >
                  <IconSignOut aria-hidden class="icon" />
                  <span>{this.$t('global.log_out')}</span>
                </NuxtLink>
              </li>
            )}
          </ul>
        </div>
      </nav>
    );
  },
});
